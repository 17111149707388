import ACCESS_NUM from "@/access/accessEnum";

/**
 *
 */
const checkAccess = (loginUser: any, needAccess = ACCESS_NUM.NOT_LOGIN) => {
  //获取当前登录用户具有的权限
  const loginUserAccess = loginUser?.userRole ?? ACCESS_NUM.NOT_LOGIN;
  if (needAccess === ACCESS_NUM.NOT_LOGIN) {
    return true;
  }
  //如果需要用户登录才能访问
  if (needAccess === ACCESS_NUM.USER) {
    //如果用户没登录，表示无权限
    if (loginUserAccess !== ACCESS_NUM.USER) {
      return false;
    }
  }
  //如果需要管理员权限
  if (needAccess === ACCESS_NUM.ADMIN) {
    if (loginUserAccess !== ACCESS_NUM.ADMIN) {
      return false;
    }
  }
  if (needAccess === ACCESS_NUM.WORKER) {
    if (loginUserAccess !== ACCESS_NUM.WORKER) {
      return false;
    }
  }
  return true;
};
export default checkAccess;
