/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponse_boolean_ } from '../models/BaseResponse_boolean_';
import type { BaseResponse_int_ } from '../models/BaseResponse_int_';
import type { BaseResponse_Notice_ } from '../models/BaseResponse_Notice_';
import type { BaseResponse_Page_Notice_ } from '../models/BaseResponse_Page_Notice_';
import type { DeleteRequest } from '../models/DeleteRequest';
import type { NoticeAddRequest } from '../models/NoticeAddRequest';
import type { NoticeQueryRequest } from '../models/NoticeQueryRequest';
import type { NoticeUpdateRequest } from '../models/NoticeUpdateRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NoticeControllerService {

    /**
     * 发布新闻公告
     * @param nar nar
     * @returns BaseResponse_int_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static addPlaceUsingPost(
nar: NoticeAddRequest,
): CancelablePromise<BaseResponse_int_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/notice/add',
            body: nar,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * 删除公告
     * @param deleteRequest deleteRequest
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static deletePlaceUsingPost(
deleteRequest: DeleteRequest,
): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/notice/delete',
            body: deleteRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * 根据 id 获取新闻公告详情
     * @param id id
     * @returns BaseResponse_Notice_ OK
     * @throws ApiError
     */
    public static getUserByIdUsingGet(
id?: number,
): CancelablePromise<BaseResponse_Notice_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/notice/get',
            query: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * 分页获取用户列表（仅工作人员）
     * @param noticeQueryRequest noticeQueryRequest
     * @returns BaseResponse_Page_Notice_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static listPlaceByPageUsingPost(
noticeQueryRequest: NoticeQueryRequest,
): CancelablePromise<BaseResponse_Page_Notice_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/notice/list/page',
            body: noticeQueryRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * 更新新闻公告信息
     * @param noticeUpdateRequest noticeUpdateRequest
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static updatePlaceUsingPost(
noticeUpdateRequest: NoticeUpdateRequest,
): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/notice/update',
            body: noticeUpdateRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
