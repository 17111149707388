import router from "@/router";

import checkAccess from "@/access/checkAccess";
import store from "@/store";
import ACCESS_NUM from "@/access/accessEnum";

// 路由导航守卫（跳转路由之前进行拦截）
router.beforeEach(async (to, from, next) => {
  // 需要的权限
  const needAccess = (to.meta?.access as string) ?? ACCESS_NUM.NOT_LOGIN;



  // 跳转的路由必须要登录
  if (needAccess !== ACCESS_NUM.NOT_LOGIN) {
    // 未登录
    if (
      !store.state.user.loginUser ||
      !store.state.user.loginUser.userRole ||
      store.state.user.loginUser.userRole === ACCESS_NUM.NOT_LOGIN
    ) {
      // 跳转到登录页面
      next('/user/login');
      return;
    }
    // 已登录，但是没有权限
    if (!checkAccess(store.state.user.loginUser, needAccess)) {
      // 路由跳转到没有权限页面
      next("/noAuth");
      return;
    }
  }
  // 如果访问的路由无需权限，直接放行
  next();
});
