/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppointmentAddRequest } from '../models/AppointmentAddRequest';
import type { AppointmentCheckRequest } from '../models/AppointmentCheckRequest';
import type { AppointmentPaymentRequest } from '../models/AppointmentPaymentRequest';
import type { AppointmentQueryRequest } from '../models/AppointmentQueryRequest';
import type { AppointmentUpdateRequest } from '../models/AppointmentUpdateRequest';
import type { BaseResponse_AppointmentStatusVO_ } from '../models/BaseResponse_AppointmentStatusVO_';
import type { BaseResponse_boolean_ } from '../models/BaseResponse_boolean_';
import type { BaseResponse_List_AppointmentNumberVO_ } from '../models/BaseResponse_List_AppointmentNumberVO_';
import type { BaseResponse_long_ } from '../models/BaseResponse_long_';
import type { BaseResponse_Page_AppointmentVo_ } from '../models/BaseResponse_Page_AppointmentVo_';
import type { DeleteRequest } from '../models/DeleteRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AppointmentControllerService {

    /**
     * 用户进行预约
     * @param appointmentAddRequest appointmentAddRequest
     * @returns BaseResponse_long_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static addAppointmentUsingPost(
appointmentAddRequest: AppointmentAddRequest,
): CancelablePromise<BaseResponse_long_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/appointment/add',
            body: appointmentAddRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * 取消预约
     * @param appointmentUpdateRequest appointmentUpdateRequest
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static cancelAppointmentUsingPost(
appointmentUpdateRequest: AppointmentUpdateRequest,
): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/appointment/cancel',
            body: appointmentUpdateRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * 检查预约场地是否冲突
     * @param appointmentCheckRequest appointmentCheckRequest
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static checkPlaceUsingPost(
appointmentCheckRequest: AppointmentCheckRequest,
): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/appointment/checkPlace',
            body: appointmentCheckRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * 删除订单
     * @param deleteRequest deleteRequest
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static deleteAppointmentUsingPost(
deleteRequest: DeleteRequest,
): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/appointment/delete',
            body: deleteRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * 获取预约个数
     * @returns BaseResponse_List_AppointmentNumberVO_ OK
     * @throws ApiError
     */
    public static getAppointmentNumberAnalysisUsingGet(): CancelablePromise<BaseResponse_List_AppointmentNumberVO_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/appointment/getAppointmentNumberAnalysis',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * 获取场地预约转化率
     * @returns BaseResponse_AppointmentStatusVO_ OK
     * @throws ApiError
     */
    public static getAppointmentStatusAnalysisUsingGet(): CancelablePromise<BaseResponse_AppointmentStatusVO_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/appointment/getAppointmentStatusAnalysis',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * 分页获取用户预约列表
     * @param appointmentQueryRequest appointmentQueryRequest
     * @returns BaseResponse_Page_AppointmentVo_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static listAppointmentVoByPageUsingPost(
appointmentQueryRequest: AppointmentQueryRequest,
): CancelablePromise<BaseResponse_Page_AppointmentVo_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/appointment/list/page/vo',
            body: appointmentQueryRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * 订单支付
     * @param appointmentPaymentRequest appointmentPaymentRequest
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static payAppointmentUsingPost(
appointmentPaymentRequest: AppointmentPaymentRequest,
): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/appointment/pay',
            body: appointmentPaymentRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * 更新预约信息
     * @param appointmentUpdateRequest appointmentUpdateRequest
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static updateAppointmentUsingPost(
appointmentUpdateRequest: AppointmentUpdateRequest,
): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/appointment/update',
            body: appointmentUpdateRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
