<template>
  <a-row id="globalHeader" :wrap="false" align="center">
    <a-col flex="auto">
      <a-menu
        :selected-keys="selectedKeys"
        mode="horizontal"
        @menu-item-click="doMenuItemClick"
      >
        <!--网站Logo及标题-->
        <a-menu-item
          key="0"
          :style="{ padding: 0, marginRight: '38px' }"
          disabled
        >
          <div class="title-bar">
            <img alt="oj" class="logo" src="../assets/logo.png" />
            <div class="title">体育公园运动场地</div>
          </div>
        </a-menu-item>
        <!--菜单功能项-->
        <a-menu-item v-for="item in visibleRoutes" :key="item.path">
          {{ item.name }}
        </a-menu-item>
      </a-menu>
    </a-col>
    <a-col flex="100px">
      <!--已登录-->
      <a-space
        v-if="
        store.state.user.loginUser?.userName &&
        store.state.user.loginUser.userRole as string !== ACCESS_NUM.NOT_LOGIN"
        direction="vertical"
        style="text-align: center"
      >
        <a-dropdown trigger="hover">
          <a-avatar v-if="store.state.user.loginUser.userAvatar">
            <img :src="store.state.user.loginUser.userAvatar" alt="已登录" />
          </a-avatar>
          <a-avatar v-else>
            <IconUser />
          </a-avatar>
          <template #content>
            <a-doption>
              <template #icon>
                <icon-idcard />
              </template>
              <template #default>
                <a-anchor-link @click="getMyUserInfo">个人信息</a-anchor-link>
              </template>
            </a-doption>
            <a-doption
              v-if=" store.state.user.loginUser.userRole as string === ACCESS_NUM.USER"
            >
              <template #icon>
                <icon-idcard />
              </template>
              <template #default>
                <a-anchor-link @click="getMyAppointInfo"
                  >我的预约
                </a-anchor-link>
              </template>
            </a-doption>
            <a-doption>
              <template #icon>
                <icon-poweroff />
              </template>
              <template #default>
                <a-anchor-link @click="logoutVisible">退出登录</a-anchor-link>
              </template>
            </a-doption>
          </template>
        </a-dropdown>
        <div>{{ store.state.user?.loginUser?.userName }}</div>
      </a-space>
      <!--未登录-->
      <a-dropdown v-else trigger="hover">
        <a-avatar>未登录</a-avatar>
        <template #content>
          <a-doption>
            <template #icon>
              <icon-user />
            </template>
            <template #default>
              <a-anchor-link href="/#/user/login">登录</a-anchor-link>
            </template>
          </a-doption>
          <a-doption>
            <template #icon>
              <icon-user-add />
            </template>
            <template #default>
              <a-anchor-link href="/#/user/register">注册</a-anchor-link>
            </template>
          </a-doption>
        </template>
      </a-dropdown>
    </a-col>
  </a-row>

  <!--个人信息对话框-->
  <a-modal
    v-model:visible="userInfoModelVisible"
    title="修改个人信息"
    @cancel="handleUserInfoCancel"
    @ok="handleUserInfoOk"
    unmountOnClose
  >
    <a-form :model="userInfo" label-align="left">
      <!--用户头像-->
      <a-upload
        action="/"
        :fileList="file ? [file] : []"
        :show-file-list="false"
        @change="onChange"
        :custom-request="uploadAvatar"
        style="margin: 0 auto 15px"
      >
        <template #upload-button>
          <div
            class="arco-upload-list-picture custom-upload-avatar"
            v-if="userInfo && userInfo.userAvatar"
          >
            <img :src="userInfo?.userAvatar" alt="" />
            <div class="arco-upload-list-picture-mask">
              <IconEdit />
            </div>
          </div>
          <div class="arco-upload-picture-card" v-else>
            <div class="arco-upload-picture-card-text">
              <IconPlus />
              <div style="margin-top: 10px; font-weight: 600">Upload</div>
            </div>
          </div>
        </template>
      </a-upload>
      <!--用户角色-->
      <a-form-item field="userRole" label="用户角色">
        <a-tag v-if="userInfo.userRole === 'user'">普通用户</a-tag>
        <a-tag v-if="userInfo.userRole === 'worker'" color="blue"
          >体育工作人员
        </a-tag>
        <a-tag v-if="userInfo.userRole === 'admin'" color="gold">管理员</a-tag>
      </a-form-item>
      <!--用户编号-->
      <a-form-item field="id" label="用户编号" tooltip="只读属性">
        <a-input v-model="userInfo.id" readonly />
      </a-form-item>
      <!--用户账号-->
      <a-form-item field="userAccount" label="用户账号" tooltip="只读属性">
        <a-input v-model="userInfo.userAccount" readonly />
      </a-form-item>
      <!--用户昵称-->
      <a-form-item field="userName" label="用户昵称">
        <a-input v-model="userInfo.userName" />
      </a-form-item>
      <!--用户介绍-->
      <a-form-item field="userProfile" label="用户介绍">
        <a-textarea
          v-model="userInfo.userProfile"
          placeholder="请输入用户介绍"
          :max-length="50"
          allow-clear
          show-word-limit
        />
      </a-form-item>
    </a-form>
  </a-modal>

  <!--退出登录对话框-->
  <a-modal :visible="visible" @cancel="handleCancel" @ok="logout">
    <template #title>确认退出登录？</template>
    <div>{{ logoutInfo }}</div>
  </a-modal>
</template>

<script lang="ts" setup>
// 导入路由表
import { routes } from "@/router/routes";
// 导入路由，进行路由跳转
import { useRoute, useRouter } from "vue-router";
// 导入响应式
import { computed, ref } from "vue";
// 导入全局状态管理VueX
import { useStore } from "vuex";
// 检查权限
import checkAccess from "@/access/checkAccess";
// Axios请求函数
import { LoginVO, UserControllerService } from "../../generated";
// 消息提示
import { FileItem, Message } from "@arco-design/web-vue";

import axios from "axios";
import ACCESS_NUM from "@/access/accessEnum";
import { baseUrl } from "@/plugins/axios";

const router = useRouter();
const store = useStore();

// 过滤路由的显隐（使用计算属性，当登录用户发生变化，会重新触发计算属性）
const visibleRoutes = computed(() => {
  return routes.filter((item) => {
    // 路由表中定义的不显示的路由
    if (item.meta?.hideInMenu) return false;

    // 根据用户的角色显示的路由，判断用户的权限（当前登录用户，路由所需要的权限）
    return checkAccess(store.state.user.loginUser, item.meta?.access as string);
  });
});

// 点击菜单功能项进行跳转对应路由
const doMenuItemClick = (key: string) => {
  router.push({ path: key });
};

// 高亮选中的菜单项（默认 /）
const selectedKeys = ref(["/"]);

// 路由跳转之后，高亮当前路由下的菜单项
router.afterEach((to) => {
  selectedKeys.value = [to.path];
});

// 个人信息对话框的显隐
const userInfoModelVisible = ref(false);

// 上传头像文件
const file = ref();

// 用户信息表单数据
const userInfo = ref();

/**
 * 点击个人信息对话框取消时触发
 */
const handleUserInfoCancel = () => {
  userInfoModelVisible.value = false;
};

/**
 * 点击个人信息对话框的确定触发
 */
const handleUserInfoOk = async () => {
  const res = await UserControllerService.updateMyUserUsingPost(userInfo.value);
  if (res.code === 0) {
    userInfoModelVisible.value = false;
    userInfo.value = res.data;
    Message.success("个人信息更新成功");
  } else {
    Message.error("个人信息更新失败，" + res.data);
  }
};

/**
 * 获取个人用户信息
 */
const getMyUserInfo = () => {
  userInfo.value = store.state.user?.loginUser;

  userInfoModelVisible.value = true;
};

/**
 * 上传头像状态发生改变时触发
 * @param _
 * @param currentFile 当前文件
 */
const onChange = async (_: never, currentFile: FileItem) => {
  file.value = {
    ...currentFile,
  };
};

/**
 * 上传头像
 */
const uploadAvatar = async () => {
  // 创建一个FormData对象，用于上传文件
  const formData = new FormData();
  formData.append("file", file.value.file); // 'file' 是文件字段的名称

  // 发送POST请求到后端接口
  await axios
    .post(baseUrl + "/api/file/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data", // 设置正确的Content-Type
      },
    })

    .then((res) => {
      console.log("上传头像结果数据：" + JSON.stringify(res.data));
      if (res.data != null) {
        userInfo.value!.userAvatar = res.data.data;
        Message.success("上传头像成功，点击确定后生效");
      } else {
        Message.error("上传头像失败！" + JSON.stringify(res.data));
      }
    })
    .catch((error) => {
      Message.error("上传头像异常！" + error);
    });
};

// 退出登录确认框的显隐
const visible = ref(false);

// 退出登录 确认框的显示
const logoutVisible = () => {
  visible.value = true;
};

// 退出登录 确认框的隐藏
const handleCancel = () => {
  visible.value = false;
};

const logoutInfo = computed(() => {
  const userName = store.state.user.loginUser?.userName;
  return (
    "燕子【" +
    userName +
    "】，你走了我可怎么活啊！" +
    userName +
    "，你带我走吧，" +
    userName +
    "！"
  );
});

// 退出登录
const logout = async () => {
  // 隐藏 退出登录的确认框
  visible.value = false;
  // 移除登录信息
  await store.dispatch("user/removeLoginUser");
  // 退出登录成功
  Message.success("我们下次再见啦~");
  // 回到主页菜单项
  await router.push({ path: "/" });
};

const getMyAppointInfo = () => {
  const userId = store.state.user?.loginUser.id;

  router.push({
    path: `/appointment/userView/${userId}`,
  });
};
</script>

<style scoped>
/*网站标题及Logo*/
.title-bar {
  display: flex;
  align-items: center;
}

/*网站Logo*/
.title-bar .logo {
  height: 60px;
}

/*网站标题*/
.title-bar .title {
  color: #444;
  margin-left: 10px;
}
</style>
