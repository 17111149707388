import { StoreOptions } from "vuex";
import ACCESS_NUM from "@/access/accessEnum";

export default {
  namespaced: true,
  state: () => ({
    loginUser: JSON.parse(<string>localStorage.getItem("userVOInfo")),
  }),

  actions: {
    // 存储用户信息
    async saveLoginUser({ commit, state }, payload) {
      const token = localStorage.getItem("token");
      if (token != null) {
        localStorage.setItem("userVOInfo", JSON.stringify(payload));

        commit("updateUser", payload);
      } else {
        commit("updateUser", {
          ...state.loginUser,
          userRole: ACCESS_NUM.NOT_LOGIN,
        });
      }
    },
    // 移除用户登录信息
    removeLoginUser({ commit, state }, payload) {
      localStorage.removeItem("token");
      localStorage.removeItem("userVOInfo");
      commit("updateUser", {
        userName: "",
        userRole: ACCESS_NUM.NOT_LOGIN,
      });
    },
  },

  mutations: {
    updateUser(state, payload) {
      state.loginUser = payload;
    },
  },
} as StoreOptions<any>;
