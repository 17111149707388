/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponse_boolean_ } from '../models/BaseResponse_boolean_';
import type { BaseResponse_int_ } from '../models/BaseResponse_int_';
import type { BaseResponse_Page_Repairs_ } from '../models/BaseResponse_Page_Repairs_';
import type { DeleteRequest } from '../models/DeleteRequest';
import type { RepairsAddRequest } from '../models/RepairsAddRequest';
import type { RepairsQueryRequest } from '../models/RepairsQueryRequest';
import type { RepairsUpdateRequest } from '../models/RepairsUpdateRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RepairsControllerService {

    /**
     * 进行报修
     * @param rap rap
     * @returns BaseResponse_int_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static addPlaceUsingPost2(
rap: RepairsAddRequest,
): CancelablePromise<BaseResponse_int_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/repair/add',
            body: rap,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * 删除公告
     * @param deleteRequest deleteRequest
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static deletePlaceUsingPost2(
deleteRequest: DeleteRequest,
): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/repair/delete',
            body: deleteRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * 分页获取用户列表（仅工作人员）
     * @param repairsQueryRequest repairsQueryRequest
     * @returns BaseResponse_Page_Repairs_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static listRepairByPageUsingPost(
repairsQueryRequest: RepairsQueryRequest,
): CancelablePromise<BaseResponse_Page_Repairs_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/repair/list/page',
            body: repairsQueryRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * 更新报修信息
     * @param repairsUpdateRequest repairsUpdateRequest
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static updatePlaceUsingPost2(
repairsUpdateRequest: RepairsUpdateRequest,
): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/repair/update',
            body: repairsUpdateRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
