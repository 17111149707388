import { RouteRecordRaw } from "vue-router";



import ACCESS_NUM from "@/access/accessEnum";


export const routes: Array<RouteRecordRaw> = [
  {
    path: "/user",
    name: "用户",
    component: ()=>import("@/layouts/UserLayout.vue"),
    children: [
      {
        path: "/user/login",
        name: "用户登录",
        component: () => import("@/views/user/UserLoginView.vue"),
      },
      {
        path: "/user/register",
        name: "用户注册",
        component: () => import("@/views/user/UserRegisterView.vue"),
      },
    ],
    meta: {
      hideInMenu: true,
    },
  },
  {
    path: "/",
    name: "主页",
    component: () => import("@/views/HomeView.vue"),
  },
  {
    path: "/location",
    name: "场地信息",
    meta: { access: ACCESS_NUM.USER, hideInMenu: false },
    component: () => import("@/views/AddressView.vue"),
  },
  {
    path: "/system",
    name: "体育场运行状况",
    meta: { access: ACCESS_NUM.WORKER, hideInMenu: false },

    component: () => import("@/views/visualizationIndex.vue"),
  },
  {
    path: "/notice",
    name: "新闻公告管理",
    meta: { access: ACCESS_NUM.WORKER, hideInMenu: false },
    component: () => import("@/views/admin/NewsManagerView.vue"),
  },
  {
    path: "/locationManager",
    name: "场地管理",
    meta: { access: ACCESS_NUM.WORKER, hideInMenu: false },
    component: () => import("@/views/admin/AddressManageView.vue"),
  },

  {
    path: "/repair",
    name: "设施维护管理",
    meta: { access: ACCESS_NUM.WORKER, hideInMenu: false },
    component: () => import("@/views/admin/RepairsManagerView.vue"),
  },
  {
    path: "/appointment",
    name: "预约管理",
    meta: { access: ACCESS_NUM.WORKER, hideInMenu: false },
    component: () => import("@/views/admin/AppointmentView.vue"),
  },
  {
    path: "/MyRepair",
    name: "我的报修记录",
    meta: { access: ACCESS_NUM.USER, hideInMenu: false },
    component: () => import("@/views/user/RepairsUserView.vue"),
  },
  {
    path: "/manage/user",
    name: "用户管理",
    meta: { access: ACCESS_NUM.ADMIN },
    component: () => import("@/views/user/UserManageView.vue"),
  },
  {
    path: "/notice/userView",
    component: () => import("@/views/NewsUserView.vue"),
  },
  {
    path: "/address/details/:id",
    component: () => import("@/views/admin/addresDetails.vue"),
    meta: { access: [ACCESS_NUM.WORKER, ACCESS_NUM.USER, ACCESS_NUM.ADMIN] },
  },

  {
    path: `/notice/details/:id`,
    component: () => import("@/views/NoticeDetails.vue"),
  },

  {
    path: "/appointment/userView/:userId",
    component: () => import("@/views/AppointmentUserView.vue"),
  },
];
