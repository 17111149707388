import axios from "axios";
// @ts-ignore
import { OpenAPI } from "../../generated";

// 携带凭证访问接口
OpenAPI.WITH_CREDENTIALS = true;

// 区分不同环境下的基础接口地址
export const baseUrl =
  process.env.NODE_ENV === "development"
    ? "http://39.101.79.177"
    : "http://39.101.79.177";

OpenAPI.BASE = baseUrl;
axios.defaults.withCredentials=true
console.log("当前环境：", process.env.NODE_ENV, "请求接口地址：", baseUrl);

// 携带token方式登录
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    console.log(token);
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  function (response: any) {
    // console.log("全局响应", response);
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    return response;
  },
  function (error: any) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);
